<template>
  <div>
    <router-link :to="{ name: 'home' }" class="white--text">
      <v-img
        src="@/assets/images/IPL_logo.png"
        alt="Innovative Pet Lab"
        max-width="150"
        class="ml-auto mr-auto mt-2 mb-2"
      />
    </router-link>
    <v-list v-for="(item, i) in items" :key="i" nav dense>
      <v-list-group
        v-if="item.children"
        v-model="item.model"
        :key="item.text"
        :prepend-icon="item.model ? item.icon : item['icon-alt']"
      >
        <v-list-item slot="activator">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="(child, i) in item.children"
          :key="i"
          :to="child.route"
        >
          <v-list-item-action v-if="child.icon">
            <v-icon>{{ child.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ child.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item v-else :key="item.text" :to="item.route">
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list nav dense>
      <v-list-item :href="exportUrl">
        <v-list-item-action>
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Export Kits </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "ApplicationNavMenu",
  data: () => ({
    items: [
      { icon: "mdi-view-dashboard", text: "Home", route: "/" },
      {
        icon: "mdi-test-tube",
        text: "Tests",
        route: "/tests",
      },
      {
        icon: "mdi-currency-usd",
        text: "Orders",
        route: "/orders",
      },
    ],
  }),
  computed: {
    // a computed getters
    exportUrl: function () {
      if (process.env.VUE_APP_API_SERVER_URL != null) {
        return `${process.env.VUE_APP_API_SERVER_URL}/export`;
      } else {
        return "https://api.innovativepetlab.com/export";
      }
    },
  },
};
</script>
