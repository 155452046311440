import { getInstance } from "./index";

export const adminGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      if (authService.user) {
        if (authService.user.role !== "admin") {
          return next({ path: "/home" });
        } else {
          return next();
        }
      }
      return next({ name: "unauthorized" });
    }
    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.isLoading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("isLoading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
