import * as mutation_types from "../mutation-types";

/**
 * @type {{show: boolean, text: string, type: string, title: string, modal: boolean}}
 */
export const state = {
  type: "",
  title: "",
  text: "",
  modal: false,
  show: false,
};

export const mutations = {
  [mutation_types.SET_USER_MSG](state, payload) {
    Object.assign(state, { ...payload, show: true });
  },
  [mutation_types.CLEAR_MSG](state) {
    Object.assign(state, {
      type: "",
      text: "",
      title: "",
      modal: false,
      show: false,
    });
  },
};

// actions
export const actions = {
  userMessage({ commit, state }, payload) {
    commit(mutation_types.SET_USER_MSG, payload);
    if (!state.modal) {
      setTimeout(() => {
        commit(mutation_types.CLEAR_MSG);
      }, 3500);
    }
  },
  clearMessage({ commit }) {
    commit(mutation_types.CLEAR_MSG);
  },
};

// getters
export const getters = {
  userMessage: (state) => {
    return { ...state };
  },
};
