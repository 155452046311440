<template>
  <div>
    <v-dialog
      v-if="userMessage.modal"
      :value.sync="userMessage.show"
      max-width="400"
      persistent
    >
      <v-card>
        <v-card-title :class="userMessage.type" class="headline white--text">{{
          userMessage.title
        }}</v-card-title>
        <v-divider />
        <v-card-text>
          <p class="text-left">{{ userMessage.text }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn :color="userMessage.type" @click.native="close" text>
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-else v-model="userMessage.show" :color="userMessage.type" top>
      {{ userMessage.text }}
      <v-btn text @click.native="close">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ApplicationUserMessage",
  computed: mapGetters(["userMessage"]),
  methods: {
    close() {
      this.$store.dispatch("clearMessage");
    },
  },
};
</script>
