<template>
  <v-app>
    <component v-bind:is="layout"></component>
  </v-app>
</template>

<script>
const default_layout = "application";
export default {
  name: "App",
  data: () => ({
    //
  }),
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
};
</script>
