<template>
  <v-app-bar fixed app dark color="primary">
    <v-app-bar-nav-icon
      v-if="$auth.isAuthenticated && drawernav"
      @click.stop="toggleDrawer"
    />
    <v-spacer />
    <template v-if="!$auth.isAuthenticated && !$auth.isLoading">
      <v-btn class="ma-2" text small @click.prevent="login"> Log In </v-btn>
    </template>
    <template v-else>
      <v-btn v-if="$auth.user" class="ma-2" text small>{{
        $auth.user.name
      }}</v-btn>
      <v-btn class="ma-2" text small @click.prevent="logout">Log Out</v-btn>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: "ApplicationToolBar",
  props: {
    drawer: {
      type: Boolean,
      required: true,
    },
    drawernav: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    appName: "Innovative Pet Lab",
    busy: false,
    user: {},
    loggedIn: false,
  }),
  methods: {
    toggleDrawer() {
      this.$emit("toggleDrawer");
    },
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin + "/login",
      });
    },
  },
};
</script>
