<template>
  <div>
    <v-app light v-if="$auth.isAuthenticated">
      <v-navigation-drawer
        v-model="drawer"
        persistent
        enable-resize-watcher
        app
        width="200"
      >
        <application-nav-menu />
      </v-navigation-drawer>
      <application-tool-bar :drawer="drawer" @toggleDrawer="drawer = !drawer" />
      <VMain class="content_port">
        <v-container fluid>
          <transition name="page" mode="out-in">
            <router-view />
          </transition>
        </v-container>
      </VMain>
      <application-message />
      <page-footer />
    </v-app>
    <v-card v-else class="mx-auto mt-10 pa-10" max-width="400">
      <v-img class="mx-auto" max-width="288" src="@/assets/images/IPL_logo.png">
      </v-img>
      <v-card-title class="pa-10">Loading...</v-card-title>
      <v-progress-linear color="primary" indeterminate />
    </v-card>
  </div>
</template>

<script>
import ApplicationNavMenu from "@/components/layout/ApplicationNavMenu";
import ApplicationToolBar from "@/components/layout/ApplicationToolBar";
import ApplicationMessage from "@/components/layout/ApplicationUserMessage";
import ApplicationPageFooter from "@/components/layout/ApplicationPageFooter";
import UserApplicationNavMenu from "@/components/layout/UserApplicationNavMenu";
export default {
  name: "ApplicationLayout",
  components: {
    "application-nav-menu": ApplicationNavMenu,
    "application-tool-bar": ApplicationToolBar,
    "application-message": ApplicationMessage,
    "page-footer": ApplicationPageFooter,
  },
  props: {
    source: String,
  },

  data: () => ({
    dialog: false,
    drawer: false,
  }),
  computed: {},
  created() {
    this.drawer = this.$vuetify.breakpoint.mdAndUp;
  },
};
</script>
<style scoped>
.content_port {
  background: #f0f0f0;
}
</style>
