import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import { authGuard } from "@/auth/authGuard";
import { adminGuard } from "@/auth/adminGuard";

Vue.use(Router);

const router = make(routes({ adminUserGuard, userGuard }));

export default router;

/**
 * Create a new router instance.
 *
 * @param  {Array} routes
 * @return {Router}
 */
function make(routes) {
  return new Router({
    routes,
    scrollBehavior,
    mode: "history",
  });
}
/* eslint-disable no-unused-vars */
function adminUserGuard(routes) {
  return beforeEnter(routes, (to, from, next) => {
    adminGuard(to, from, next);
    // next();
  });
}
/**
 *
 * @param  {Array} routes
 * @return {Array}
 */
/* eslint-enable no-unused-vars */
/* eslint-disable no-unused-vars */
function userGuard(routes) {
  return beforeEnter(routes, (to, from, next) => {
    authGuard(to, from, next);
  });
}

/**
 * Apply beforeEnter guard to the routes.
 *
 * @param  {Array} routes
 * @param  {Function} beforeEnter
 * @return {Array}
 */
/* eslint-enable no-unused-vars */
function beforeEnter(routes, beforeEnter) {
  return routes.map((route) => {
    return { ...route, beforeEnter };
  });
}
/**
 * @param  {Route} to
 * @param  {Route} from
 * @param  {Object|undefined} savedPosition
 * @return {Object}
 */
function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }

  const position = {};

  if (to.hash) {
    position.selector = to.hash;
  }

  if (to.matched.some((m) => m.meta.scrollToTop)) {
    position.x = 0;
    position.y = 0;
  }

  return position;
}
const DEFAULT_TITLE = "Innovative Pet Lab";

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = "IPL :: " + (to.meta.title || DEFAULT_TITLE);
  });
});
