<template>
  <div>
    <VApp>
      <VMain>
        <v-container fluid>
          <transition name="page" mode="out-in">
            <router-view />
          </transition>
        </v-container>
      </VMain>
      <application-message />
      <page-footer /> </VApp
    >>
  </div>
</template>

<script>
import ApplicationMessage from "@/components/layout/ApplicationUserMessage";
import ApplicationPageFooter from "@/components/layout/ApplicationPageFooter";
export default {
  name: "BlankLayout",
  components: {
    "application-message": ApplicationMessage,
    "page-footer": ApplicationPageFooter,
  },
  props: {
    source: String,
  },

  data: () => ({
    dialog: false,
    drawer: false,
  }),
  computed: {},
};
</script>
