import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createProvider } from "./vue-apollo";
import "core-js/stable"; // only stable feature also is possible with only `core-js`
import "regenerator-runtime/runtime"; // To ensure that regeneratorRuntime is defined globall
import vuetify from "./plugins/vuetify";
import { Auth0Plugin } from "./auth";
import ApplicationLayout from "@/layouts/ApplicationLayout";
import UserApplicationLayout from "@/layouts/UserApplicationLayout";
import BlankLayout from "@/layouts/BlankLayout";
Vue.config.productionTip = false;
const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
const audience = process.env.VUE_APP_AUTH0_AUDIENCE;
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  // Store: store,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});
Vue.config.productionTip = false;
Vue.component("application-layout", ApplicationLayout);
Vue.component("user-application-layout", UserApplicationLayout);
Vue.component("blank-layout", BlankLayout);
new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
