<template>
  <v-footer :fixed="fixed" app>
    <v-row justify="center">
      <v-btn color="primary" text class="my-2">
        &copy; {{ new Date().getFullYear() }}
        <strong>Innovative Pet Lab</strong>
      </v-btn>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "ApplicationPageFooter",
  data() {
    return {
      fixed: false,
    };
  },
  computed: {
    thisYear: function () {
      return new Date().getFullYear();
    },
  },
  methods: {},
};
</script>
