<template>
  <div>
    <v-img
      src="@/assets/images/IPL_logo.png"
      alt="Innovative Pet Lab"
      max-width="150"
      class="ml-auto mr-auto mt-2 mb-2"
    />
    <v-list v-for="(item, i) in items" :key="i" nav dense>
      <v-list-group
        v-if="item.children"
        v-model="item.model"
        :key="item.text"
        :prepend-icon="item.model ? item.icon : item['icon-alt']"
      >
        <v-list-item slot="activator">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="(child, i) in item.children"
          :key="i"
          :to="child.route"
        >
          <v-list-item-action v-if="child.icon">
            <v-icon>{{ child.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ child.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item v-else :key="item.text" :to="item.route">
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list nav dense>
      <v-list-item href="https://innovativepetlab.com/shop" target="_blank">
        <v-list-item-action>
          <v-icon>mdi-shopping</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Shop </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        href="https://innovativepetlab.com/results-defined/"
        target="_blank"
      >
        <v-list-item-action>
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Results Defined</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        href="https://innovativepetlab.com/research/"
        target="_blank"
      >
        <v-list-item-action>
          <v-icon>mdi-book-open-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Research</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "UserApplicationNavMenu",
  data: () => ({
    items: [
      { icon: "mdi-view-dashboard", text: "Home", route: "/" },
      {
        icon: "mdi-dog",
        text: "My Pets",
        route: "/my-pets",
      },
      {
        icon: "mdi-test-tube",
        text: "My Tests",
        route: "/my-tests",
      },
    ],
  }),
};
</script>
