export default ({ adminUserGuard, userGuard }) => [
  // guest routes
  // Guest routes.
  {
    path: "/unauthorized",
    name: "unauthorized",
    meta: { layout: "blank" },

    component: () =>
      import(/* webpackChunkName: "unauth" */ "@/views/auth/Unauthorized.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/auth/Login.vue"),
    meta: { layout: "blank" },
  },

  ...adminUserGuard([
    {
      path: "/",
      name: "admin-home",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
      meta: { layout: "application", title: "Home" },
    },
    {
      path: "/tests",
      name: "tests",
      meta: { layout: "application", title: "Tests" },
      component: () =>
        import(/* webpackChunkName: "tests" */ "@/views/tests/Tests.vue"),
    },

    {
      path: "/orders",
      name: "orders",
      meta: { layout: "application", title: "Orders" },
      component: () =>
        import(/* webpackChunkName: "orders" */ "@/views/orders/Orders.vue"),
    },
    {
      path: "/users",
      name: "users",
      meta: { layout: "application", title: "Users" },
      component: () =>
        import(/* webpackChunkName: "users" */ "@/views/users/Users.vue"),
    },
  ]),
  ...userGuard([
    {
      path: "/home",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
      meta: { layout: "user-application", title: "Home" },
    },
    {
      path: "/my-tests",
      name: "MyTests",
      meta: { layout: "user-application", title: "My Tests" },
      component: () =>
        import(/* webpackChunkName: "my-tests" */ "@/views/my-tests/MyTests"),
    },
    {
      path: "/my-tests/register",
      name: "RegisterTest",
      meta: { layout: "user-application", title: "Register New Test" },
      component: () =>
        import(/* webpackChunkName: "my-tests" */ "@/views/my-tests/MyTests"),
      props: { startRegisterProcess: true },
    },
    {
      path: "/my-pets",
      name: "mypets",
      meta: { layout: "user-application", title: "My Pets" },
      component: () =>
        import(/* webpackChunkName: "my-pets" */ "@/views/my-pets/MyPets"),
    },
    {
      path: "/profile",
      name: "profile",
      meta: { layout: "user-application", title: "Profile" },
      component: () =>
        import(/* webpackChunkName: "profile" */ "@/views/Profile"),
    },
  ]),
  {
    path: "*",
    name: "not-found",
    meta: { layout: "blank", title: "Not Found" },
    component: () =>
      import(/* webpackChunkName: "notfound" */ "@/views/PageNotFound"),
  },
];
